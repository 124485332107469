/* CSS HEX */
/* --black: #000000ff;
--powder-blue: #9AB8CEff;
--indigo-dye: #144367ff;
--rich-black: #010D1Aff;
--prussian-blue: #042847ff; */

* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;  
}
*::-webkit-scrollbar {
  display: none; 
}

a {
  text-decoration: none;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  margin-left: 1%;
}

.About {
  margin-top: 10vh;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.about_text {
  text-align: left;
  width: 75%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0%;
  margin: 0;
}

.App.light {
  background-color: #ffffff;
}

.App-logo {
  width: 40px;
  height: 40px;
  align-self: center;
}

.blue {
  color: #00cbf4
}

.btn_box {
  justify-content: space-around;
  align-items: center;
}

.Buy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
}

.buy_btn {
  width: 80%;
  /* background-image: linear-gradient(direction, color-stop1, color-stop2, ...); */
  background-image: linear-gradient(30deg, #042847ff, #042847ff, #282c34);
  border-radius: 10px;
  border-color: #00d1fa;
  border-style: ridge;
  border-width: 2px;
  /* box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit; */
  box-shadow: 1px 1px 20px 1px #00cbf431;
  height: 50px;
  color: #00d1fa;
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buy_btn:hover {
  border: solid 1px #00d1fa;
  box-shadow: 0px 0px 10px 1px #00d1fa;

}

.center {
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.Dashboard {
  width: 100vw;
  margin-top: 10vh;
  height: 80vh;
}

.disclaimer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;
}

.disclaimer_text {
  width: 90%;
  text-align: left;
  margin-bottom: 10px;
    text-shadow: none;
}

.form {
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 80%;
}

.game_btn {
  width: 40%;
  /* background-image: linear-gradient(direction, color-stop1, color-stop2, ...); */
  background-image: linear-gradient(30deg, #042847ff, #042847ff, #282c34);
  filter: blur(0.5px);
  border-radius: 25px;
  border-color: #00cbf444;
  border-style: ridge;
  border-width: 2px;
  /* box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit; */
  box-shadow: 1px 1px 20px 1px #00cbf431;
  height: 50px;
  color: #ffffff;
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gameboard {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.gameplan_card {
  width: 90%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #282c34;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border: 1px solid #00cbf4;
  background: linear-gradient(71deg, #080509, #2d2b2e, #080509);
  background-clip: padding-box;
}

.gameplan_title {
  font-size: 2em;
  text-align: center;
  width: 50%;
}
.gameplan_text {
  text-align: left;
  width: 75%;
}

.graphic {
  width: 50%;
  border-radius: 20px;
  /* margin-bottom: 10px; */
  margin-top: 8vh;
  z-index: 0;
}

.green {
  color: #15ff00;
}

.ham {
  width: 50px;
  font-size: 2em;
  cursor: pointer;
  margin-right: 5%;
}

.Header {
  background-color: #000000;
  height: 7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0;
  margin: 0%;
  position: fixed;
  top: 0;
  z-index: 1;
}

.hero_logo {
  width: 50%;
}

.hero_text {
  font-size: 2em;
  font-family: "Lato", sans-serif;
  color: #ffffff;
  text-shadow: 5px 5px 5px #000000;
  text-transform: uppercase;
  margin-top: 20px;

}

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 2vh;
}

.icon {
  width: 50px;
  font-size: 1.8em;
  cursor: pointer;
}

input {
  width: 80%;
  background-image: linear-gradient(30deg, rgb(255, 255, 255), #282c341b);
  filter: blur(0.5px);
  border-radius: 25px;
  border-color: #00cbf444;
  border-style: ridge;
  border-width: 2px;
  /* box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit; */
  box-shadow: 1px 1px 20px 1px #00cbf431;
  height: 50px;
  color: #042847ff;
  font-size: 1.5em;
  text-align: center;
  margin-top: 10px;
  font-family: "Lato", sans-serif;
}

.input_box {
  width: 100%;
  justify-content: center;
  align-items: center;
}

#login_btn {
  margin-top: 10px;
}

.logo_box {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
}

.menu_list {
  width: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: space-around;
  height: fit-content;
  color: aliceblue;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.5em;
}

ul {
  list-style: none;
}

li {
  padding: 5px;
  display: flex;
  justify-content: space-around;
}

.Login {
  justify-content: center;
  align-self: center;
}

.message {
  font-size: 1.5rem;
  background-color: #9ab8ceff;
  border-radius: 15px;
  color: #042847ff;
}

.nav {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

p {
  font-size: 1em;
  width: 90%;
  text-shadow: 1px 1px 2px #282c34;
}

.parallax_bg {
  /* display: inline-block; */
  padding: 0;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  z-index: -1;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  pointer-events: none;
  object-fit: cover;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: "hidden";
  
}

.presale {
  color: white;
  font-size: 2em;
  width: 100%;
}

.qimage {
  width: 100vw;
}

.red {
  color: #ff0000;
}

.Register {
  justify-content: center;
  align-self: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.section_1 {
  width: 100vw;
  height: 100vh;
  align-items: center;
  margin-top: 5%;
  /* background-color: #042847ff; */
}

.section_2 {
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.section2_card_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.section2_img_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.section_3 {
  background-color: #ffffff;
  /* background: linear-gradient(180deg, #080509, #ffffff,#ffffff); */
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.section_title {
  color: #00cbf4;
  font-size: 3em;
  justify-self: flex-start;
}

.section_3_wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.social_btn:hover {
  border: solid 1px #00d1fa;
  box-shadow: 0px 0px 10px 1px #00d1fa;
  border-radius: 50%;
}

.social_media_btn_box {
  width: 50%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  align-self: center;
  align-items: center;
  text-align: center;
}

.stat {
  font-size: 3em;
}

.stat_box {
  min-width: 85vw;
  max-width: 100vw;
  justify-content: space-around;
  padding: 20px;
  border-bottom: 2px solid rgb(255, 255, 255);
  margin-bottom: 20px;
  margin-top: 20px;
}

.stat_box_dash {
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.stat_card {
  width: 40%;
  background-color: #042847ff;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px;
}

#supply_card {
  width: 90%;
  height: auto;
}

.swap_form {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.swap_logo {
  width: 45px;
  height: 45px;
}

.text {
  font-family: "Lato", sans-serif;
  color: #ffffff;
  text-shadow: 5px 5px 5px #000000;

}

.text.dark {
  color: #ffffff;
}
.text.light {
  color: #000000;
  text-shadow: 1px 1px 2px #282c34;
}

.text_box {
  width: 95%;
  background-color: #000000;
  border-radius: 25px;
  align-self: center;
  justify-self: center;
  text-align: left;
}

.timer_card {
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #00cbf4;
  background: linear-gradient(71deg, #080509, #2d2b2e, #080509);
  background-clip: padding-box;
  width: 20vw;
}
.timer_card_title {
  color: #00cbf4;
  width: 100%;
}

.timer_card_text {
  font-size: 2em;
  width: 100%;
}

.timer_outer_wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.timer_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1em;
  padding: 5px;
  font-family: "Lato", sans-serif;
  text-shadow: 1px 1px 2px #282c34;
  text-transform: uppercase;
  margin-left: 1%;
  color: #00cbf4;
}

.tokenomics_card {
  width: 90%;
  height: auto;
  color: white;
  background-color: #282c34;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border: 1px solid #00cbf4;
  background: linear-gradient(71deg, #080509, #2d2b2e, #080509);
  background-clip: padding-box;
}

.tokenomics_title {
  font-size: 2em;
}
.tokenomics_text {
  text-align: left;
  width: 75%;
}

.tokenomics_chart{
  width: 100%;
  height: 50%;
}

.tokenomics_img {
  display: none;
  
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .graphic {
    animation: spin infinite 20s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Desktop View */
@media only screen and (min-width: 1000px) {
.about_text {
  text-align: left;
  width: 50%;
}

  .buy_btn {
    width: 25%;
    height: 50px;
    font-size: 1.5em;
    margin-top: 0px;
  }

  .form {
    width: 100%;
    min-height: 80%;
  }
  .gameboard {
    width: 70%;
    align-self: center;
    justify-content: center;
  }

  .gameplan_card {
    width: 30%;
  }

  .graphic {
    width: 15%;
  }

  .ham {
    margin-left: 20px;
  }

  .hero_text {
    font-size: 2.75em;
    width: 100%;
  }

  .Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* padding-top: 20px; */
    height: 100%;
    /* padding-bottom: 20px; */
    width: 100%;
    align-self: center;
  }

  .menu_list {
    width: 15%;
    align-self: flex-end;
  }

  .menu_text {
    font-size: 0.5em;
  }

  .qimage {
    width: 100%;
  }

  .section_1 {
  margin-top: 5%;
  /* background-color: #042847ff; */
}

.section_2 {
  flex-direction: row;
}
.section2_img_wrapper {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section2_card_wrapper {
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

  .section_3_wrapper {
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  }

  .social_media_btn_box {
  width: 25%;
}

#supply_card {
  width: 80%;
  height: auto;
}

.text {
  font-size: 2;
}

.timer_card {
  width: 10vw;
}

.tokenomics_img {
  width: 80%;
  object-fit: contain;
  display: block;
  align-self: center;
}

.tokenomics_card {
  /* display: none; */
  width: 45%;
  height: 20vh;
}

}

/* Tablet View */
@media only screen and (min-width: 768px) and (min-height: 1024px) and (max-width: 1080){
 
}

@media only screen and (min-width: 400px) and (max-height: 700px) {
  #buy_btn {
    width: 80%;
    height: 50px;
    font-size: 1.5em;
  }

  .hero_text {
    font-size: 2em;
  }
}
